<template>
	<div class="photography-details" v-if='showpage'>
		<BannerOther :img_url="img_url" :chinese_name='chinese_name' :english_name="english_name"></BannerOther>
		<div class="col-main-box">
			<Sidder :tab_selectIndex='tab_selectIndex'></Sidder>
			<div class="col-r-box">
				<!-- 导航栏 -->
				<div class="crumbs">
					<div class="cru-logo">
						<img src="../../assets/images/home/daohang_logo.png" alt="">
					</div>
					<el-breadcrumb separator=">">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/wedding-photography',query:back_query}" v-if="operate == '婚礼摄影'">{{operate}}
						</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/personal-center',query:{personsiderIndex:2,type:f_type}}" v-if="operate == '我的收藏'">{{operate}}
						</el-breadcrumb-item>
						<el-breadcrumb-item>{{selectname}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="v-details-box">
					<div class="v-main-box">
						<div class="v-l-info">
							<div class="v-l-logo">
								<img src="../../assets/images/sidder/chuangshiren.png" alt="" v-if="segment == '创始人'">
								<img src="../../assets/images/sidder/zongjian.png" alt="" v-if="segment == '总监'">
								<img src="../../assets/images/sidder/shouxi.png" alt="" v-if="segment == '首席'">
								<img src="../../assets/images/sidder/gaoji.png" alt="" v-if="segment == '高级'">
								<img src="../../assets/images/sidder/zhongji.png" alt="" v-if="segment == '中级'">
								<img src="../../assets/images/sidder/zhuanye.png" alt="" v-if="segment == '专业'">
								<img src="../../assets/images/sidder/hlsx_huangjin.png" alt="" v-if="segment == '黄金'">
								<img src="../../assets/images/sidder/hlsx_baiyin.png" alt="" v-if="segment == '白银'">
								<img src="../../assets/images/sidder/hlsx_qingtong.png" alt="" v-if="segment == '青铜'">
							</div>
							<div class="info-details">
								<div class="inf-det-name">{{selectname}}</div>
								<div class="quantity">
									<span>浏览量</span>
									<span class="black-color">{{camera_info.views}}</span>
								</div>
								<div class="quantity">
									<span>收藏量</span>
									<span class="black-color">{{camera_info.collect}}</span>
								</div>
							</div>
							<div class="v-l-peopleinfo">
								<div class="people-item">
									<img src="../../assets/images/sidder/hlsx_shexiangshi.png" alt="">
									<span>{{camera_info.option}}</span>
								</div>
								<!-- <div class="people-item">
									<img src="../../assets/images/sidder/hlsx_xingbie.png" alt="">
									<span>{{camera_info.gender}}</span>
								</div>
								<div class="people-item">
									<img src="../../assets/images/sidder/hlsx_shengao.png" alt="">
									<span>{{camera_info.height}}</span>
								</div>
								<div class="people-item">
									<img src="../../assets/images/sidder/hlsx_tizhong.png" alt="">
									<span>{{camera_info.weight}}</span>
								</div> -->
							</div>
							<div class="public-title">详情描述</div>
							<div class="describe">{{camera_info.desc}}</div>
							<div class="public-title">亮点标签</div>
							<div class="highlight-label">
								<div class="label-item" v-for="(item,index) in camera_info.tag" :key="index">{{item}}</div>
							</div>
							<div class="price">
								<div class="public-title">服务价格</div>
								<span class="peice-num">￥{{camera_info.price}}</span>
							</div>
							<div class="all-btn">
								<div class="button h-button" @click="openframe('立即下单')">立即下单</div>
								<div class="button b-button" @click="openframe('下单须知')">下单须知</div>
								<div class="button sc-button" @click="changeshow">
									<img src="../../assets/images/sidder/hlsx_shoucanged.png" alt="" v-if="showstart">
									<img src="../../assets/images/sidder/hlsx_shoucang.png" alt="" v-else>
									<span>收藏</span>
								</div>
							</div>
						</div>
						<div class="plg-item">
							<div class="top-box"></div>
							<div class="footer-box"></div>
							<img :src="camera_info.image" alt="">
						</div>
					</div>
					<!-- 小屏幕 -->
					<div class="v-main-box v-main-box1">
						<div class="v-l-info">
							<div class="v-l-logo">
								<img src="../../assets/images/sidder/chuangshiren.png" alt="" v-if="segment == '创始人'">
								<img src="../../assets/images/sidder/zongjian.png" alt="" v-if="segment == '总监'">
								<img src="../../assets/images/sidder/shouxi.png" alt="" v-if="segment == '首席'">
								<img src="../../assets/images/sidder/gaoji.png" alt="" v-if="segment == '高级'">
								<img src="../../assets/images/sidder/zhongji.png" alt="" v-if="segment == '中级'">
								<img src="../../assets/images/sidder/zhuanye.png" alt="" v-if="segment == '专业'">
								<img src="../../assets/images/sidder/hlsx_huangjin.png" alt="" v-if="segment == '黄金'">
								<img src="../../assets/images/sidder/hlsx_baiyin.png" alt="" v-if="segment == '白银'">
								<img src="../../assets/images/sidder/hlsx_qingtong.png" alt="" v-if="segment == '青铜'">
							</div>
							<div class="newstyle">
								<div class="plg-item">
									<div class="top-box"></div>
									<div class="footer-box"></div>
									<img :src="camera_info.image" alt="">
								</div>
								<div class="right-info">
									<div class="inf-det-name">{{selectname}}</div>

									<div class="v-l-peopleinfo">
										<div class="people-item">
											<img src="../../assets/images/sidder/hlsx_shexiangshi.png" alt="">
											<span>{{camera_info.option}}</span>
										</div>
									</div>
									<div class="v-l-peopleinfo">
										<div class="people-item">
											<img src="../../assets/images/sidder/hlsx_xingbie.png" alt="">
											<span>{{camera_info.gender}}</span>
										</div>
									</div>
									<div class="v-l-peopleinfo">
										<div class="people-item">
											<img src="../../assets/images/sidder/hlsx_shengao.png" alt="">
											<span>{{camera_info.height}}</span>
										</div>
									</div>
									<div class="v-l-peopleinfo">
										<div class="people-item">
											<img src="../../assets/images/sidder/hlsx_tizhong.png" alt="">
											<span>{{camera_info.weight}}</span>
										</div>
									</div>
									<div class="info-details">
										<div class="quantity">
											<span>浏览量</span>
											<span class="black-color">{{camera_info.views}}</span>
										</div>
										<div class="quantity">
											<span>收藏量</span>
											<span class="black-color">{{camera_info.collect}}</span>
										</div>
									</div>
								</div>

							</div>
							<div class="public-title">个人描述</div>
							<div class="describe">{{camera_info.desc}}</div>
							<div class="public-title">亮点标签</div>
							<div class="highlight-label">
								<div class="label-item" v-for="(item,index) in camera_info.tag" :key="index">{{item}}</div>
							</div>
							<div class="price">
								<div class="public-title">服务价格</div>
								<span class="peice-num">￥{{camera_info.price}}</span>
							</div>
							<div class="all-btn">
								<div class="button h-button" @click="openframe('立即下单')">立即下单</div>
								<div class="button b-button" @click="openframe('下单须知')">下单须知</div>
								<div class="button sc-button" @click="changeshow">
									<img src="../../assets/images/sidder/hlsx_shoucanged.png" alt="" v-if="showstart">
									<img src="../../assets/images/sidder/hlsx_shoucang.png" alt="" v-else>
									<span>收藏</span>
								</div>
							</div>
						</div>

					</div>



					<div class="hui-fenge"></div>
					<div class="anchor-point">
						<div class="point-item" v-for="(item,index) in allTab" :key="index"
							:class="select_tab == index ? 'point-active':''" @click="changetab(index)">
							<span>{{item}}</span>
							<div class="point-line" v-if="select_tab == index"></div>
							<div class="point-line point-line1" v-else></div>
						</div>
					</div>
					<!-- <div class="schedule" id="jump1" ref="jump1">
						<div class="sch-title">
							<div class="sch-line"></div>
							<span>档期情况</span>
						</div>
						<div class="table">
							<el-table :data="tableData" stripe style="width: 100%">
								<el-table-column  label="档期"  min-width="150" align="center" header-align="center">
									<template slot-scope="scope">
										{{scope.row.day}}{{scope.row.time}}
									</template>
								</el-table-column>
								<el-table-column prop="hotel" min-width="150" label="酒店名称" align="center" header-align="center">
								</el-table-column>
								<el-table-column prop="province" min-width="150" label="地区" align="center" header-align="center">
								</el-table-column>
							</el-table>
						</div>
						<div class="see-more" @click="loadingMore" v-show="showmore">查看更多</div>
					</div> -->
					<div class="schedule" id="jump2" ref="jump2">
						<div class="sch-title">
							<div class="sch-line"></div>
							<span>摄影赏析</span>
						</div>
						<div class="all-video">
							<div class="video-item" v-for="(item,index) in videoArr" :key="index"
								:style="{backgroundImage:'url('+item.image+')'}"
								@click="openvideo(item.id)">{{item.name}}</div>
						</div>
					</div>
					<div class="schedule" id="jump1" ref="jump1">
						<div class="sch-title">
							<div class="sch-line"></div>
							<span>服务详情</span>
						</div>
						<div class="service-details" v-html="camera_info.service" :class="seeMore?'more-box':'hidd-box'" ></div>
						<div class="more-btn" @click="changeseemore('服务详情')">{{seeMore?'收起 >':'查看全部 >'}}</div>
					</div>
					<div class="schedule" id="jump3" ref="jump3">
						<div class="sch-title">
							<div class="sch-line"></div>
							<span>介绍</span>
						</div>
						<div class="service-details" v-html="camera_info.content" :class="seeMore1?'more-box':'hidd-box'" ></div>
						<div class="more-btn" @click="changeseemore('介绍')">{{seeMore1?'收起 >':'查看全部 >'}}</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 下单须知弹框 -->
		<div class="frame1 frame2">
			<el-dialog title="下单须知" :visible.sync="dialogVisible">
				<div class="notice-box" v-html="order_notice"></div>
			</el-dialog>
		</div>

		<!-- 立即下单 -->
		<el-dialog title="婚礼拍摄预定" :visible.sync="dialogVisible1">
			<div class="buy-box">
				<PurchaseFrame @newval='getval' :id="id" :yd_content="yd_content" :czname="czname" @openkf="getopenkf"></PurchaseFrame>
			</div>
		</el-dialog>
		<!-- 付款 -->
		<el-dialog title="订单提交成功，请尽快付款" :visible.sync="dialogVisible2">
			<div class="pay-box">
				<div class="pay-left">
					<div class="pay-list">
						<span>订单总金额：</span>
						<div><span class="p-b-color">{{money_info.total_money}}</span>元</div>
					</div>
					<div class="pay-list">
						<span>红包优惠：</span>
						<div><span class="p-xr-color">-{{money_info.discount_price}}</span>元</div>
					</div>
					<div class="pay-list">
						<span>差旅费：</span>
						<div><span class="p-xr-color">{{money_info.travel_price}}</span>元</div>
					</div>
					<div class="pay-list">
						<span>支付定金：</span>
						<div><span class="p-dr-color">{{money_info.deposit}}</span>元</div>
					</div>
				</div>
				<div class="pay-right">
					<div class="pay-code">
						<img src="../../assets/images/home/code.png" alt="">
					</div>
					<div class="pay-notice">打开微信，扫描二维码支付</div>
				</div>
			</div>
		</el-dialog>
		<!-- 视频赏析弹窗 -->
		<div class="video-box">
			<el-dialog :title="video_name" :visible.sync="dialogVisible3" @close="cancel">
				<YsTeachVideo :videoUrl='videoUrl' :shownum='0'></YsTeachVideo>
			</el-dialog>
		</div>
		<div class="frame1 frame3">
			<el-dialog title="客服" :visible.sync="dialogTableVisible" @close="closeclick('客服')" :close-on-click-modal="false">
				<Customer></Customer>
			</el-dialog>
		</div>
	</div>

</template>

<script>
	import YsTeachVideo from '@/components/ys-teach-video/index.vue'
	import BannerOther from '@/components/banner-other/index.vue'
	import Sidder from '@/components/sidder/index.vue'
	import PurchaseFrame from '@/components/purchase-frame/index.vue'
	import Customer from '@/components/customer-frame/index.vue'
	import {
		shoucangList,
		removeshoucangList,
		getsheyingDetails
	}from '@/api/joggle.js'
	export default {
		name:'Photography-Details',
		components: {
			BannerOther,
			Sidder,
			PurchaseFrame,
			YsTeachVideo,
			Customer
		},
		data() {
			return {
				dialogTableVisible:false,
				dialogVisible:false,
				dialogVisible1:false,
				dialogVisible2:false,
				dialogVisible3: false,
				img_url: '',
				chinese_name: '婚礼摄影',
				english_name: 'WEDDING PHOTOGRAPHY',
				tab_selectIndex: 1,
				segment: '',
				showstart: false,
				// '档期情况',
				allTab: ['摄影赏析', '服务详情', '介绍'],
				select_tab: 0,
				tableData: [],
				videoArr: [],
				selectname:'', //摄影师姓名
				id:'',// 摄影师id
				camera_info:{} ,// 详情信息
				order_notice:'', //下单须知
				operate:'' ,// 上级页面名称
				yd_content:'', //预定内容
				logined:false, //是否登录
				money_info:'',//个人预定成功后的信息
				f_type:'摄影师', //上一个页面tab选项（仅限从我的收藏进入）
				alltableData: [], // 档期所有数据
				showmore: false, // 是否显示加载更多按钮
				videoUrl:'',
				video_name:'',
				back_query:{
					search_date:'',
					search_time:'',
					top_keyword:''
				},
				seeMore:false,
				seeMore1:false,
        czname:'婚礼摄影',
				showpage:false,
				showpage1:false,
			}
		},
		mounted() {
			console.log('that.$forceUpdate();')
		},
		
		activated() {
			var that = this
			console.log('created')
			// 获取头图(栏目图)
			var company_info = JSON.parse(localStorage.getItem('company_info'));
			if(company_info){
				that.img_url = company_info.sheying_image;
			}
			that.back_query.search_date = that.$route.query.search_date;
			that.back_query.search_time = that.$route.query.search_time;
			that.back_query.top_keyword = that.$route.query.top_keyword;
			that.operate = that.$route.query.operate;
			// 从我的收藏进入则不选中侧边栏
			if(that.operate == '我的收藏'){
				that.tab_selectIndex = null;
			}
			that.selectname = that.$route.query.name;
			// 修改浏览器显示名称
			document.title = that.selectname + '-婚礼摄影-MG影像';
			that.yd_content = '摄影师-' + that.selectname;
			that.id = that.$route.query.id;
			// 获取下单须知
			var obj = JSON.parse(localStorage.getItem('company_info'));
			if(obj){
				that.order_notice = obj.notice;
			}
			// 查看是否登录
			var info = JSON.parse(localStorage.getItem('user_info'));
			if(info){
				that.logined = true;
			}else{
				that.logined = false;
			}

			that.getDetails();
		},
		
		methods: {
		
			changeseemore(name){
				var that = this
				if(name == '服务详情'){
					that.seeMore = !that.seeMore;
				}
				if(name == '介绍'){
					that.seeMore1 = !that.seeMore1;
				}
			},
			closeclick(name){
				var that = this
				if(name == '客服'){
					that.dialogTableVisible = false;
				}
				// document.getElementsByClassName('v-modal')[0].style.display = 'none';
				// document.getElementsByClassName('el-popup-parent--hidden')[0].style.overflow = 'auto';
			},
			// 关闭视频赏析
			cancel(){
				var that = this
				that.dialogVisible3 = false;
				that.video_name = '';
				that.videoUrl = '';
			},
			// 点击视频赏析
			openvideo(id){
				var that = this
				that.$router.push({
					path: '/photo-details',
					query: {
						zid: id,
						operate:that.operate,
						id:that.id,
						name:that.selectname,
						top_keyword:that.back_query.top_keyword
					}
				})
				// var token = JSON.parse(localStorage.getItem('user_info'));
				// token = token?token.token:''
				// if(token){

				// }else{
				// 	that.$message.error('请先登录！')
				// }

				// that.dialogVisible3 = true;
				// that.video_name = name;
				// that.videoUrl = url;
			},
			// 点击加载更多
			loadingMore() {
				var that = this
				that.tableData = that.alltableData;
				that.showmore = false; // 隐藏加载更多
			},
			// 获取详情信息
			getDetails(){
				var that = this
				var obj ={
					id: that.id
				}
				getsheyingDetails(obj).then(res => {
					that.showpage = true;
					if(res.code == 1){
						var data = res.data;
						if(data.level == 1){
							that.segment = '创始人'
						}
						if(data.level == 2){
							that.segment = '总监'
						}
						if(data.level == 3){
							that.segment = '首席'
						}
						if(data.level == 4){
							that.segment = '高级'
						}
						if(data.level == 5){
							that.segment = '中级'
						}
						if(data.level == 6){
							that.segment = '专业'
						}
						if(data.level == 7){
							that.segment = '黄金'
						}
						if(data.level == 8){
							that.segment = '白银'
						}
						if(data.level == 9){
							that.segment = '青铜'
						}
						that.camera_info = data;
						that.camera_info.tag.forEach((item,index)=>{
							if(item == ''){
								that.camera_info.tag.splice(index,1)
							}
						})


						if(that.camera_info.type == 1){
							that.camera_info.option = '摄影师';
						}else if(that.camera_info.type == 2){
							that.camera_info.option = '摄像师';
						}else{
							that.camera_info.option = '';
						}
						// 是否被收藏
						if(that.camera_info.is_collect == 0){ // 未收藏
							that.showstart = false;
						}
						if(that.camera_info.is_collect == 1){ // 已收藏
							that.showstart = true;
						}
						// 档期
						that.alltableData = data.order;
						if (data.order.length > 10) {
							that.tableData = data.order.slice(0, 10);
							that.showmore = true; // 显示加载更多
						} else {
							that.tableData = data.order;
							that.showmore = false; // 隐藏加载更多
						}
						// 视频赏析
						that.videoArr = data.video_list;
						
					}
				})
			},
			
      getopenkf(val){
        let that = this
        that.dialogVisible1 = false;
        that.dialogTableVisible = true;

      },
			// 关闭下单框时
			getval(msg,shenfen,money_info){
				var that = this
				that.dialogVisible1 = msg;
				that.dialogTableVisible = true;
				if(shenfen == '个人'){
					// that.dialogVisible2 = true;
					that.money_info = money_info;
				}
				if(shenfen == '公司'){
					that.getDetails();
				}
			},
			// 点击收藏
			changeshow() {
				var that = this
				// 先判断时候登录
				if(that.logined){
					that.showstart = !that.showstart;
					var obj ={
						id: that.id
					}
					if(that.showstart){//收藏
						that.Collect(obj);
					}else{ //取消收藏
						that.CancelCollect(obj);
					}
				}else{
					that.$message.error('请先登录！')
				}
			},
			// 收藏
			Collect(obj){
				var that = this
				shoucangList(obj).then(res => {
					that.$message.success(res.msg);
				})
			},
			// 取消收藏
			CancelCollect(obj){
				var that = this
				removeshoucangList(obj).then(res => {
					that.$message.success(res.msg);
				})
			},
			changetab(index) {
				var that = this
				that.select_tab = index;
				// 锚点定位
				var id = index + 1;
				this.$el
					.querySelector("#jump" + id)
					.scrollIntoView({
						block: "start",
						behavior: "smooth"
					});

			},
			// 打开弹框
			openframe(name){
				var that = this
				if(name == '下单须知'){
					that.dialogVisible = true;
				}
				if(name == '立即下单'){
					if(that.logined){ //已登录
						that.dialogVisible1 = true;
					}else{
						that.$message.error('请先登录！')
					}
				}
			}
		},
		// 离开页面事件
		beforeDestroy() {
			var that = this
		},
	}
</script>

<style lang="scss">
	@import '../../assets/css/photography-details.scss';
	@import '../../assets/css/public.scss';
</style>
